<script setup lang="ts">
import { useButtons } from '~/components/BaseForm/compose/use-buttons'
import groupFields from '~/components/Destination/form/groupField'
import { useURL } from '~/composables/useURL'
import { $dayjs } from '@/utils/dayjs'
import type { SupportedCurrencies } from 'lc-services/types'
import type { MetaAncestries } from '~/types/types'

const { $api, $httpCookies } = useNuxtApp()
const { getBaseURL } = useURL()
const { t, locale } = useI18n()
const route = useRoute()
const { trackEvent } = useTracking()
const { currency } = useUser()

const ancestries = route.meta?.ancestries as MetaAncestries | undefined

$dayjs.locale(locale.value)

const props = withDefaults(
  defineProps<{
    destiId?: number | null
    isLpInspiration?: boolean
    lpInspirationName?: string
    lpInspirationSlug: string
  }>(),
  {
    destiId: null,
    isLpInspiration: false,
    lpInspirationName: '',
    lpInspirationSlug: '',
  },
)

const { btnPrimary } = useButtons()
btnPrimary.text = computed(() => t('inquiry.form.sendRequest'))
const initialData = reactive({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  calendar: {
    startDate: '',
    endDate: '',
  },
  budget: '',
  currency: currency.value,
  guests: '',
})
const disabledPrimaryButton = computed(() => {
  if (
    initialData.firstname &&
    initialData.lastname &&
    initialData.phone &&
    initialData.email &&
    initialData.calendar.startDate &&
    initialData.calendar.endDate &&
    initialData.budget &&
    initialData.guests
  ) {
    return false
  }

  return true
})
const showValidation = ref(false)

const groupFieldsForm = reactive(groupFields())

const createRequest = (formData: unknown) => $api.v1.requests.create(formData)
const mutation = (data: {
  budget: number
  calendar: {
    startDate: Date | string
    endDate: Date | string
  }
  currency: SupportedCurrencies
  email: string
  firstname: string
  guests: number
  lastname: string
  phone: string
}) => ({
  request: createRequest,
  input: {
    currency: data.currency,
    request: {
      destination_id: props.destiId || undefined,
      start_date: data.calendar.startDate,
      end_date: data.calendar.endDate,
      budget: data.budget,
      people: data.guests,
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      phone: data.phone,
      landing_page_name: props.isLpInspiration
        ? props.lpInspirationName
        : undefined,
      landing_page_url: props.isLpInspiration
        ? `${getBaseURL()}/en/luxury-villas-rentals/${props.lpInspirationSlug}`
        : undefined,
    },
    hubspotutk: $httpCookies?.get('hubspotutk'),
  },
})
const onSubmit = () => ({
  success: (_: unknown, __: unknown, resetForm: () => void) => {
    showValidation.value = true

    tracking()
    resetForm()
  },
  error: (err: unknown) => {
    console.warn(err)
  },
})

const onFinish = () => {
  showValidation.value = false
}
const tracking = () => {
  trackEvent({
    cluster_id: ancestries?.clusterId,
    cluster_name: ancestries?.clusterName,
    country_id: ancestries?.countryId,
    country_name: ancestries?.countryName,
    destination_id: ancestries?.destinationId,
    destination_name: ancestries?.destinationName,
    event: 'form_sent_lpdestination',
    value: Number(initialData.budget),
    hash: {
      email: initialData.email,
      phone: initialData.phone,
    },
  })
}
</script>

<template>
  <base-container class="desti-form">
    <base-success-form
      v-model="showValidation"
      class="lc-col relative mx-auto w-24/24 lg:w-16/24"
      @on-finish="onFinish"
    >
      <base-form
        ref="destiForm"
        :active-modal-errors="false"
        :active-modal-save-or-quit="false"
        :button-primary="btnPrimary"
        :disabled-primary-button="disabledPrimaryButton"
        :group-fields="groupFieldsForm"
        :initial-data="initialData"
        :mutation="mutation"
        :on-submit="onSubmit"
        container-button-class="mt-2 flex-wrap"
        id-form="desti-form"
        wrapper-button-class="flex flex-col-reverse"
      />
    </base-success-form>
  </base-container>
</template>

<style>
.desti-form {
  @apply relative justify-center;
}
.desti-form .input-budget input:focus::placeholder {
  @apply text-transparent;
}
.desti-form .input-budget input::placeholder {
  @apply text-sm;
}
.desti-form .base-field-info {
  @apply opacity-0 transition-all;
}
.desti-form .base-field-info.input-has-focus,
.desti-form .base-field-info.input-has-value {
  @apply opacity-100 transition-all;
}

.desti-form__form {
  @apply justify-center opacity-100;
  transition: opacity 0.3s ease-in 0.5s;
}
.desti-form__form.is-hidden {
  @apply opacity-0;
  transition: opacity 0.3s ease-in;
}
.desti-form__form .switch-currency button {
  @apply text-secondary-500 h-full;
}

@screen md {
  .desti-form__calendar .datepicker__wrapper .datepicker {
    width: 466px;
  }
}

@screen lg {
  .desti-form__calendar .datepicker__wrapper .datepicker {
    @apply w-auto;
  }
}

.desti-form__validation-row {
  @apply absolute top-0 left-0 w-full h-full mx-auto;
}
.desti-form__validation-row.is-visible {
  @apply z-10;
}

.desti-form__validation {
  @apply absolute top-1/2 opacity-0 text-center cursor-default;
  transform: translateY(100%);
  transition:
    opacity 0.3s ease-in,
    transform 0.5s ease-in;
}

@screen md {
  .desti-form__validation {
    max-width: 600px;
    transform: translate(-50%, 100%);
    @apply left-1/2;
  }
}
.desti-form__validation .desti-form__validation-button {
  @apply cursor-default;
}
.desti-form__validation-row.is-visible .desti-form__validation {
  @apply opacity-100;
  transform: translateY(-50%);
  transition:
    opacity 0.3s ease 0.3s,
    transform 0.5s 0.3s ease;
}

@screen md {
  .desti-form__validation-row.is-visible .desti-form__validation {
    transform: translate(-50%, -50%);
  }
}
.desti-form__validation-row.is-visible
  .desti-form__validation
  .desti-form__validation-button {
  @apply cursor-default;
}

.desti-form__validation-button {
  @apply w-full;
}

@screen md {
  .desti-form__validation-button {
    @apply w-auto;
    min-width: 200px;
  }
}

.desti-form__icon {
  @apply font-medium align-baseline mb-4;
}

.section-budget .btn {
  @apply w-full;
}
</style>
